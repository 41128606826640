import Vue from "vue";
import Vuex from "vuex";
import * as types from "./mutation-types";
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
const PREMIUM_PRICE = 23;
const REGULAR_PRICE = 19;
const EXTRA_SHISHA_PRICE = 15;
const EXTRA_HEAD_PRICE = 9;

const getDefaultState = () => {
  return {
    added: [],
  };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
  getProduct: (state) => (id) => {
    return state.added.find((p) => p.id === id);
  },
  totalCost: (state) =>
    state.added
      ? state.added.reduce((total, p) => {
          return total + p.price;
        }, 0)
      : 0,

  totalQuantity: (state) =>
    state.added
      ? state.added.reduce((total, p) => {
          return total + p.quantity;
        }, 0)
      : 0,

  cartProducts: (state) => {
    return state.added;
  },
};

// actions
const actions = {
  addToCart({ commit }, product) {
    commit(types.ADD_TO_CART, {
      type: product.type,
      flavor: product.flavor,
      quantity: product.quantity,
    });
  },
  deleteProduct({ commit }, payload) {
    commit(types.DELETE_PRODUCT, {
      type: payload.type,
      flavor: payload.flavor,
      quantity: payload.quantity,
      price: payload.price,
    });
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
};

// mutations
const mutations = {
  [types.DELETE_PRODUCT](state, { type, flavor, quantity, price }) {
    state.added = state.added.filter(
      (p) => !(p.type === type && p.flavor === flavor)
    );
    if (
      (type === "Premium Tobacco Shisha" ||
        type === "Regular Tobacco Shisha") &&
      price / quantity > EXTRA_SHISHA_PRICE
    ) {
      let temp = state.added.find(
        (p) =>
          p.type === "Premium Tobacco Shisha" &&
          p.price / p.quantity == EXTRA_SHISHA_PRICE
      );
      if (temp != null) {
        temp.price += PREMIUM_PRICE - EXTRA_SHISHA_PRICE;
      } else {
        let temp = state.added.find(
          (p) =>
            p.type === "Regular Tobacco Shisha" &&
            p.price / p.quantity == EXTRA_SHISHA_PRICE
        );
        if (temp != null) {
          temp.price += REGULAR_PRICE - EXTRA_SHISHA_PRICE;
        }
      }
      // When deleting 23$ premium => find if premium extra head exists,
      // then replace $19 regular shisha with premium shisha and make this extra head regular
      if (type === "Premium Tobacco Shisha") {
        let premiumExtraHead = state.added.find(
          (p) => p.type === "Extra Head" && isPremium(p.flavor)
        );
        if (premiumExtraHead) {
          state.added = state.added.filter(
            (p) =>
              !(
                p.type === premiumExtraHead.type &&
                p.flavor === premiumExtraHead.flavor
              )
          );
          addToCart(state, {
            type: premiumExtraHead.type,
            flavor: premiumExtraHead.flavor,
            quantity: premiumExtraHead.quantity,
          });
        }
      }
    }
    //TODO if only extra heads in cart then remove them
  },
  [types.ADD_TO_CART](state, { type, flavor, quantity }) {
    addToCart(state, { type, flavor, quantity });
  },
  [types.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
};

function addToCart(state, { type, flavor, quantity }) {
  var price;
  if (type === "Regular Tobacco Shisha") {
    const temp = state.added.find(
      (p) =>
        p.type === "Premium Tobacco Shisha" ||
        p.type === "Regular Tobacco Shisha"
    );
    if (temp) {
      price = EXTRA_SHISHA_PRICE * quantity;
    } else {
      price = REGULAR_PRICE + EXTRA_SHISHA_PRICE * (quantity - 1);
    }
  } else if (type === "Extra Head") {
    // if extra head is premium => replace $19 regular shisha with premium shisha and make this extra head regular
    if (isPremium(flavor)) {
      const replaced = replaceRegularWithPremium(state, {
        flavor,
      });
      if (replaced) {
        quantity--;
        if (quantity == 0) {
          return;
        }
      }
    }
    price = EXTRA_HEAD_PRICE * quantity;
  } else {
    let temp = state.added.find((p) => p.type === "Premium Tobacco Shisha");
    if (temp) {
      price = EXTRA_SHISHA_PRICE * quantity;
    } else {
      price = PREMIUM_PRICE + EXTRA_SHISHA_PRICE * (quantity - 1);
    }
    temp = state.added.find((p) => p.type === "Regular Tobacco Shisha");
    if (temp != null) {
      temp = state.added.find(
        (p) =>
          p.type === "Regular Tobacco Shisha" &&
          p.price / p.quantity > EXTRA_SHISHA_PRICE
      );
      if (temp != null) {
        temp.price -= REGULAR_PRICE - EXTRA_SHISHA_PRICE;
      }
    }
  }

  const record = state.added.find(
    (p) => p.type === type && p.flavor === flavor
  );

  if (!record) {
    let id = uuidv4();
    state.added.push({
      id,
      type,
      price,
      flavor,
      quantity,
    });
  } else {
    record.price = record.price + price;
    record.quantity = record.quantity + quantity;
  }
}
function replaceRegularWithPremium(state, { flavor }) {
  // find regular with price 19
  const temp = state.added.find(
    (p) =>
      p.type === "Regular Tobacco Shisha" &&
      p.price / p.quantity > EXTRA_SHISHA_PRICE
  );
  if (!temp) {
    return false;
  }
  // remove it or reduce quantity
  if (temp.quantity == 1) {
    // remove it
    state.added = state.added.filter(
      (p) => !(p.type === temp.type && p.flavor === temp.flavor)
    );
  } else {
    temp.quantity--;
    temp.price -= REGULAR_PRICE;
  }

  // add premium shisha
  addToCart(state, {
    type: "Premium Tobacco Shisha",
    flavor: flavor,
    quantity: 1,
  });

  // add regular extra head
  addToCart(state, {
    type: "Extra Head",
    flavor: temp.flavor,
    quantity: 1,
  });
  return true;
}
function isPremium(flavor) {
  const premium_flavors = [
    "GREEN FOREST (Cucumber, Pear, Zero)",
    "KAMASUTRA PLEASURE (Kamasutra, Pineapple)",
    "FROZEN BEAR (Black-Box, Pear)",
    "BORA BORA (cucumber, Black-Box)",
    "BLUE MOON (Blueberry, Peach)",
  ];
  if (premium_flavors.includes(flavor)) {
    return true;
  }
  return false;
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      getState: (key) => Cookies.getJSON(key),
      setState: (key, state) =>
        Cookies.set(key, state, { expires: 1 / 24, secure: true }), // expires in an hour
    }),
  ],
  state,
  strict: debug,
  getters,
  actions,
  mutations,
});
