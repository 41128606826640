<template>
  <div
    id="app"
    class="content-wrapper bg-background-primary text-copy-primary flex flex-col min-h-screen font-sans globalBg"
  >
    <header>
      <nav
        class="container flex flex-wrap justify-between items-center py-4 px-4 mx-auto"
      >
        <!-- <div class="block lg:hidden">
          <button
            @click="toggle"
            class="flex items-center px-3 py-2 border rounded border-gray-500 hover:text-gray-600 hover:border-gray-600"
            aria-label="toggle menu"
          >
            <svg
              class="current-color h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
                fill="gray"
              />
            </svg>
          </button>
        </div>
        <ul
          class="font-medium w-full block flex-grow lg:flex lg:flex-initial lg:w-auto items-center mt-8 lg:mt-0"
          :class="isOpen ? 'block' : 'hidden'"
        >
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.home") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/`"
              v-scroll-to="'#about'"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.about") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/`"
              v-scroll-to="'#pricing'"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.pricing") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/`"
              v-scroll-to="'#contact'"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.contact") }}</router-link
            >
          </li>
          <li class="mr-8 mb-6 lg:mb-0">
            <router-link
              :to="`/${$i18n.locale}/order`"
              class="text-copy-primary hover:text-gray-600"
              >{{ $t("navbar.order") }}</router-link
            >
          </li>

          <li class="mr-8 mb-6 lg:mb-0">
            <language-switcher />
          </li>
          <li class="mb-4 lg:mb-0">
            <shopping-cart ref="cart" />
          </li>
        </ul> -->
      </nav>
    </header>

    <div class="flex-grow">
      <router-view />
    </div>
    <!-- Footer -->
    <footer class="text-gray-600 body-font gradientMagic">
      <div
        class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col"
      >
        <!-- <img src="@/assets/logo-white.png" alt="logo" class="h-14" />
        <span class="ml-3 text-2xl text-gray-50">{{
          $t("pages.index.inferno_shisha_services")
        }}</span>

        <p
          class="text-base text-gray-50 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-50 sm:py-2 sm:mt-0 mt-4"
        >
          {{ $t("pages.index.shisha_delivery") }}
        </p>
        <span
          class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start"
        >
          <a
            href="https://www.facebook.com/people/Inferno-Shisha-Services/100063685891186/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="facebook"
            class="enlarge cursor-pointer text-gray-50"
          >
            <svg
              fill="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="w-8 h-8"
              viewBox="0 0 24 24"
            >
              <path
                d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
              ></path>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/infernoshishaservices/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="facebook"
            class="enlarge cursor-pointer ml-4 text-gray-50"
          >
            <svg
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              class="w-8 h-8"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path
                d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"
              ></path>
            </svg>
          </a>
        </span> -->
      </div>
    </footer>
    <!-- END Footer -->
  </div>
</template>

<script>
// import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
// import ShoppingCart from "@/components/ShoppingCart.vue";

export default {
  name: "app",
  // components: {
  //   LanguageSwitcher,
  //   ShoppingCart,
  // },
  // data() {
  //   return {
  //     isOpen: false,
  //   };
  // },
  // methods: {
  //   toggle() {
  //     this.isOpen = !this.isOpen;
  //   },
  // },
  // watch: {
  //   $route() {
  //     this.isOpen = false;
  //   },
  // },
};
</script>

<style src="@/assets/css/styles.css" />
