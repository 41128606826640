import Vue from "vue";
import Router from "vue-router";
// import i18n from "./i18n";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return { x: 0, y: 0 };
  },
  routes: [
    // {
    //   path: "/",
    //   redirect: i18n.locale,
    // },
    // {
    //   path: "/:lang(en|gr)",
    //   component: {
    //     render: (h) => h("router-view"),
    //   },
    //   children: [
    //     {
    //       path: "",
    //       name: "home",
    //       component: () =>
    //         import(/* webpackChunkName: "home" */ "./views/Home.vue"),
    //     },
    //     {
    //       path: "order",
    //       name: "order",
    //       component: () =>
    //         import(/* webpackChunkName: "about" */ "./views/Order.vue"),
    //       pathToRegexpOptions: { strict: true },
    //     },
    //     {
    //       path: "checkout",
    //       name: "checkout",
    //       component: () =>
    //         import(/* webpackChunkName: "about" */ "./views/Checkout.vue"),
    //       pathToRegexpOptions: { strict: true },
    //     },
    //     {
    //       path: "*",
    //       component: () => import("./views/404.vue"),
    //     },
    //   ],
    // },
    {
      path: "*",
      component: () => import("./views/Construction.vue"),
    },
  ],
});
