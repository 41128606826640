import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueScrollTo from "vue-scrollto";
import VueMeta from "vue-meta";
import "tailwindcss/tailwind.css";
import VCalendar from "v-calendar";
import store from "./store/index.js";
import Vuelidate from "vuelidate";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueGtag from "vue-gtag";

Vue.use(VueReCaptcha, { siteKey: "6LdYmbYaAAAAAIACyAyKVGaYRsKALeckfsXoeJqP" });
Vue.use(Vuelidate);
Vue.use(VCalendar);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = "gr";
  }

  // set the current language for i18n.
  i18n.locale = language;
  next();
});

Vue.use(
  VueGtag,
  {
    config: { id: "G-0HELT9ZB8H" },
  },
  router
);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
